<template>
  <loading
    v-model:active="is_loading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  <article class="message is-warning is-small" v-if="error_message">
    <div class="message-body">
      {{ error_message }}
    </div>
  </article>
  <div class="content">
    <div class="account-edit-title">アカウント情報変更</div>
    <div class="field">
      <fieldset disabled>
        <div class="field form-custom">
          <label class="label">メールアドレス</label>
          <input type="text" class="input" :value="user_form.email" />
          <span class="help"
            >※メールアドレスを変更することはできません。メールアドレスを変更したい場合は、再度アカウントを作成してください。</span
          >
        </div>
      </fieldset>
      <div class="field form-custom">
        <label class="label">姓</label>
        <input
          type="text"
          class="amplify-input"
          v-model="user_form.family_name"
          placeholder="姓"
        />
        <span v-if="validation_errors.family_name" class="help is-danger">
          {{ validation_errors.family_name[0] }}
        </span>
      </div>
      <div class="field form-custom">
        <label class="label">名</label>
        <input
          type="text"
          class="amplify-input"
          v-model="user_form.given_name"
          placeholder="名"
        />
        <span v-if="validation_errors.given_name" class="help is-danger">
          {{ validation_errors.given_name[0] }}
        </span>
      </div>
      <div class="field">
        <label class="label">生年月日</label>
        <div class="birthdate">
          <div class="year">
            <input
              type="number"
              class="amplify-input"
              v-model="user_form.birthdate.year"
              oninput="if(value.length > 4) value=value.slice(0,4)"
              pattern="^[0-9]+$"
              placeholder="2001"
            />
          </div>
          <div class="pr-2 pl-2">年</div>
          <div class="month">
            <input
              type="number"
              class="amplify-input"
              v-model="user_form.birthdate.month"
              oninput="if(value.length > 2) value=value.slice(0,2)"
              pattern="^[0-9]+$"
              placeholder="1"
            />
          </div>
          <div class="pr-2 pl-2">月</div>
          <div class="day">
            <input
              type="number"
              class="amplify-input"
              v-model="user_form.birthdate.day"
              oninput="if(value.length > 2) value=value.slice(0,2)"
              pattern="^[0-9]+$"
              placeholder="1"
            />
          </div>
          <div class="pr-2 pl-2">日</div>
        </div>
        <span v-if="validation_errors.birthdate" class="help is-danger">
          {{ validation_errors.birthdate[0] }}
        </span>
      </div>

      <div class="field form-custom">
        <div class="label">性別</div>
        <div class="control">
          <label class="radio mr-3">
            <input
              type="radio"
              name="gender"
              v-model="user_form.gender"
              :value="genders.man"
            />
            男性
          </label>
          <label class="radio mr-3">
            <input
              type="radio"
              name="gender"
              v-model="user_form.gender"
              :value="genders.woman"
            />
            女性
          </label>
          <label class="radio">
            <input
              type="radio"
              name="gender"
              v-model="user_form.gender"
              :value="genders.no_answer"
            />
            回答しない
          </label>
        </div>
        <span v-if="validation_errors.gender" class="help is-danger">
          {{ validation_errors.gender[0] }}
        </span>
      </div>
      <div class="field form-custom">
        <label class="label">電話番号</label>
        <input
          type="tel"
          class="amplify-input"
          pattern="^[0-9]+$"
          v-model="user_form.phone_number"
          placeholder="09012345678"
        />
        <span v-if="validation_errors.phone_number" class="help is-danger">
          {{ validation_errors.phone_number[0] }}
        </span>
      </div>
      <div class="field">
        <label class="label">郵便番号</label>
        <input
          type="text"
          class="amplify-input"
          v-model="user_form.locale"
          pattern="^[0-9]+$"
          oninput="if(value.length > 7) value=value.slice(0,7)"
          placeholder="1008086"
        />
        <span v-if="validation_errors.locale" class="help is-danger">
          {{ validation_errors.locale[0] }}
        </span>
      </div>
    </div>
    <div class="btn-wrapper">
      <button class="button is-rounded btn-submit" @click="updateUserAttr">
        更新
      </button>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify"
import Loading from "vue-loading-overlay"
import { parsePhoneNumber, isValidNumber } from "libphonenumber-js"
import validate from "validate.js"
import moment from "moment"
import settings from "../settings"

export default {
  name: "UserEdit",
  components: {
    Loading,
  },
  async beforeMount() {
    this.is_loading = true
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.user = user
        this.user_form.email = user.attributes.email
        this.user_form.family_name = user.attributes.family_name
        this.user_form.given_name = user.attributes.given_name
        if (user.attributes.birthdate) {
          const birthdate = new Date(user.attributes.birthdate)
          this.user_form.birthdate.year = birthdate.getFullYear()
          this.user_form.birthdate.month = birthdate.getMonth() + 1
          this.user_form.birthdate.day = birthdate.getDate()
        }
        this.user_form.gender = user.attributes.gender
        if (user.attributes.phone_number) {
          const national_number = user.attributes.phone_number.substr(3)
          this.user_form.phone_number = national_number
        }
        this.user_form.locale = user.attributes.locale
        this.is_loading = false
      })
      .catch(() => {
        this.error_message = "アカウント情報を取得できませんでした。"
        this.is_loading = false
      })
  },
  data() {
    return {
      is_loading: false,
      error_message: "",
      user: {},
      validation_errors: {},
      user_form: {
        email: "",
        family_name: "",
        given_name: "",
        phone_number: "",
        locale: "",
        gender: "",
        birthdate: {
          year: "",
          month: "",
          day: "",
        },
      },
      genders: settings.genders,
    }
  },
  computed: {
    birthdate() {
      const isHalfSizeNumber = (value) => {
        if (!value) {
          return false
        }
        const half_size_pattern = /^[0-9]+$/
        return value.match(half_size_pattern)
      }
      const year = this.user_form.birthdate.year.toString().padStart(4, "0")
      const month = this.user_form.birthdate.month.toString().padStart(2, "0")
      const day = this.user_form.birthdate.day.toString().padStart(2, "0")
      if (
        !isHalfSizeNumber(year) ||
        !isHalfSizeNumber(month) ||
        !isHalfSizeNumber(day)
      ) {
        return ""
      }
      return `${year}-${month}-${day}`
    },
  },
  methods: {
    validate(form_data) {
      const error_messages = {
        presence: "^この項目は必須です",
        datetime: "^半角数字で有効な日付を入力してください",
        numericality: "^半角数字のみで入力してください",
        valid_phone_number: "^有効な電話番号を入力してください",
        inclusion: "^有効な値が選択されていません",
      }
      // 国番号付き電話番号のバリデーション
      validate.validators.validPhoneNumber = (value) => {
        if (value) {
          if (isValidNumber(value)) {
            return
          }
        }
        return error_messages.valid_phone_number
      }

      // 日付の設定
      validate.extend(validate.validators.datetime, {
        parse: (value, options) => {
          return +moment.utc(value)
        },
        format: (value, options) => {
          let format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss"
          return moment.utc(value).format(format)
        },
      })

      const constraints = {
        family_name: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
        },
        given_name: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
        },
        phone_number: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
          numericality: {
            message: error_messages.numericality,
          },
          validPhoneNumber: true,
        },
        locale: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
          numericality: {
            message: error_messages.numericality,
          },
          length: {
            is: 7,
            message: "^7桁で入力してください",
          },
        },
        gender: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
          inclusion: {
            within: Object.keys(this.genders).map((key) => {
              return this.genders[key]
            }),
            message: error_messages.inclusion,
          },
        },
        birthdate: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
          datetime: {
            dateOnly: true,
            earliest: moment("1900-01-01"),
            message: error_messages.datetime,
          },
        },
      }
      const validate_result = validate(form_data, constraints)
      return validate_result
    },
    async updateUserAttr() {
      this.validation_errors = {}
      const form_data = {
        family_name: this.user_form.family_name,
        given_name: this.user_form.given_name,
        phone_number: `+81${this.user_form.phone_number}`,
        gender: this.user_form.gender,
        locale: this.user_form.locale,
        birthdate: this.birthdate,
      }
      const validation_errors = this.validate(form_data)
      if (validation_errors) {
        this.validation_errors = validation_errors
        return
      }
      this.is_loading = true
      Auth.currentAuthenticatedUser()
        .then(async (user) => {
          await Auth.updateUserAttributes(user, form_data)
          this.is_loading = false
          this.$router.push({ path: "/user/edit-complete" })
        })
        .catch(() => {
          this.error_message = "アカウント情報の更新に失敗しました。"
          this.is_loading = false
        })
    },
  },
}
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.account-edit-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}
.btn-wrapper {
  text-align: center;
  padding: 30px;
}
.field {
  margin-bottom: 15px;
}
.label {
  margin-bottom: 3px;
}
.birthdate {
  display: flex;
  align-items: flex-end;
}
.birthdate .year {
  width: 40%;
}
.birthdate .month {
  width: 20%;
}
.birthdate .day {
  width: 20%;
}
</style>
